<template>
  <nav>
    <v-app-bar flat app class="top-bar">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">Syncio</span>
        <span> Admin</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="light" v-on:click="logoutUser">
        <span>Sign Out</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" class="primary">
      <v-list>
        <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-icon>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { LOGOUT } from "@/store/actions.type";

export default {
  name: "Navbar",
  data () {
    return {
      drawer: true,
      links: [
        { icon: 'dashboard', text: 'Dashboard', route: '/dashboard' },
        { icon: 'store', text: 'Stores', route: '/stores' },
        { icon: 'people', text: 'Users', route: '/users' },
        { icon: 'description', text: 'Reports', route: '/reports' },
        { icon: 'groups', text: 'Staffs', route: '/staffs' },
        { icon: 'mdi-server', text: 'Servers', route: '/servers' }
      ],
    }
  },
  methods: {
    logoutUser() {
      this.$store.dispatch(`auth/${LOGOUT}`).then( () => this.$router.push({ name: "LoginPage" }));
    }
  }
}
</script>

<style scoped>
.top-bar {
  border-bottom: 1px solid #ccc
}
</style>