<template>
<v-app class="grey lighten-4">
  <Navbar/>
  <v-container class="mx4 mb-4">
    <router-view></router-view>
  </v-container>
</v-app>
</template>

<script>
import Navbar from "@/views/components/Navbar";
export default {
name: "DashboardLayout",
  components: {Navbar},
}
</script>

<style scoped>

</style>